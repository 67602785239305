import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Suv from '../assets/images/suv.png'
import Tracktor from '../assets/images/tracktor.png'
import Sedan from '../assets/images/sedan2.png'
import Bus from '../assets/images/bus.png'

function ServicesAndPricing() {
  const services = [
    { 
      description: 'Ավտոմեքենայի տարհանում', 
      price: 'սկսած 5 000 դրամից', 
      image: Sedan
    },
    { 
      description: 'Ամենագնացի տարհանում', 
      price: 'սկսած 7 000 դրամից', 
      image: Suv 
    },
    { 
      description: 'Հատուկ տեխնիկայի տարհանում', 
      price: 'սկսած 15 000 դրամից', 
      image: Tracktor
    },
   
    { 
      description: 'Միկրոավտոբուսի տարհանում', 
      price: 'սկսած 10 000 դրամից', 
      image: Bus
    },
  ];

  const numCols = 2; // Количество столбцов
  // const colStyle = { maxWidth: '300px' }; // Стиль для столбцов
  const imgStyle = { maxWidth: '500px', height: 'auto', width:"100%", minWidth:"200px" }; // Стиль для изображений

  const numRows = Math.ceil(services.length / numCols); // Количество строк

  // Функция для рендеринга одной строки
  const renderRow = (startIndex) => {
    return (
      <Row key={startIndex} className="justify-content-center">
        {services.slice(startIndex, startIndex + numCols).map((service, index) => (
          <Col key={index} >
            <div className="text-center">
            <p >{service.description}</p>
            <p style={{fontWeight:"bold"}}>Գին (սկսած) - <b>{service.price}</b></p>
              <img src={service.image} alt="Service" style={imgStyle} />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  // Рендеринг всех строк
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(renderRow(i * numCols));
    }
    return rows;
  };

  return (
    <Container className="mt-5" id="tariff">
      <h2 className="text-center mb-4">Ձեր ավտոմեքենայի քարշակման գները</h2>
      <p className="text-center" style={{marginBottom:"100px"}}>Մենք սպասարկում ենք Երևանը և Հայաստանի ողջ տարածաշրջանը</p>
      {renderRows()}
    </Container>
  );
}

export default ServicesAndPricing;
