import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa';

function CallButton() {
  const phoneNumber = '37498018788'; // Номер телефона для набора

  const handleCallNow = () => {
    // Ваш код для набора номера телефона
    console.log('Calling now...');
  };

  return (
    <Button
      variant="primary"
      style={{
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        zIndex: '9999', // Чтобы кнопка была поверх остального контента
        display: 'flex',
        alignItems: 'center' // Чтобы иконка была выровнена по центру кнопки
      }}
      onClick={handleCallNow}
      href={`tel:${phoneNumber}`} // Добавляем номер телефона в атрибут href
    >
      <FaPhone style={{ marginRight: '5px' }} /> Զանգել հիմա
    </Button>
  );
}

export default CallButton;
