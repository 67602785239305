import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaWhatsapp, FaViber, FaPhoneAlt } from "react-icons/fa";
import FooterLogo from "../assets/icons/truck.png";

function Footer() {
  const phoneNumber = '37498018788'; // Номер телефона для контактов

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  const handleViberClick = () => {
    window.open(`viber://chat?number=${phoneNumber}`, '_blank');
  };

  const handlePhoneCallClick = () => {
    window.open(`tel:${phoneNumber}`, '_blank');
  };

  return (
    <footer className="mt-5 bg-dark text-white" style={{ height: '300px' }}>
      <Container>
        <h2 className="text-center mb-5"> Evakuator-24-7.am </h2>
        <Row className="justify-content-center"> {/* Центрирование столбцов */}
          <Col style={{ maxWidth: '300px' }}> {/* Максимальная ширина 300px */}
            <div style={{maxHeight:"200px", maxWidth:"200px", backgroundColor:"#fff", borderRadius:"50%", height:"100%" , width:"100%",}}><img style={{height:"100%" , width:"100%", padding:"30px", objectFit:"contain"}} src={FooterLogo} alt="" /></div>
          </Col>
          <Col style={{ maxWidth: '300px' }}> {/* Максимальная ширина 300px */}
            <h5 className='text-center'>Հետադարձ կապ</h5>
            <ul className="list-unstyled">
              <li>
                <a style={{border:"2px solid #25D366", borderRadius:"10px", padding:"5px",textDecoration:"none"}} href={`https://wa.me/${phoneNumber}`} onClick={handleWhatsAppClick} className="d-flex align-items-center text-white whatsapp mb-2">
                  <FaWhatsapp style={{color:"#25D366"}} className="mr-2" size={24} /> <nav style={{marginLeft:"15px"}}> WhatsApp</nav>
                </a>
              </li>
              <li>
                <a  style={{border:"2px solid #7663F2", borderRadius:"10px", padding:"5px" ,textDecoration:"none"}} href={`viber://chat?number=${phoneNumber}`} onClick={handleViberClick} className="d-flex align-items-center text-white viber mb-2">
                  <FaViber style={{color:"#7663F2"}} className="mr-3" size={24} /> <nav style={{marginLeft:"15px"}}> Viber </nav>
                </a>
              </li>
              <li>
                <a  style={{border:"2px solid #0D6EFD" ,borderRadius:"10px", padding:"5px",textDecoration:"none"}} href={`tel:${phoneNumber}`} onClick={handlePhoneCallClick} className="d-flex align-items-center text-white phone mb-2">
                  <FaPhoneAlt style={{color:"#0D6EFD"}} className="mr-2" size={18} /> <nav style={{marginLeft:"15px"}}> Phone Call </nav>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
