import React from 'react';
import { Container } from 'react-bootstrap'; // Импортируем компонент Container из Bootstrap

function About() {
  return (
    <section className="section" id="about">
      <Container> {/* Обертываем контент в компонент Container для Bootstrap */}
        <div className="mt-5 text-center section-content">
          <h2 className=" section-title">Ինչու է մեր մոտ մատչելի</h2>
          <p className="mt-3">Ստորև կպատասխանենք, թե ինչու է մեր մոտ տարհանումը էժան։ Մեր ծառայությունները առանց միջնորդի են, ուստի գները ցածր են։ Ուղևորատար մեքենայի փոխադրման պատվիրման արժեքը 5000 դրամից է սկսում։ Հատուկ տեխնիկան տարհանում ենք՝ սկսած 7000 դրամից։ Ջիպ, մինիվեն կամ գազել տեղափոխելը նույնպես էժան է՝ սկսած 7000 դրամից:</p><br></br>
          <p>Գինը մարզերում՝ հաշվարկվում է ըստ պայմանավորվածությամբ։ Այն փոխադրումը հաճախորդի համար դարձնում է թափանցիկ և էժան։ Օպերատորը ճշգրիտ հաշվարկներ է կատարում և կանչից առաջ հայտնում է վերջնական արժեքը:</p>
          <br></br>
          <p>Բացառություններ կարող են լինել, եթե ..</p>
          <ol style={{maxWidth:"500px", margin:"0 auto"}} className='text-center'>
            <li>արգելափակված անիվների դեպքում՝ գինը բարձրանում է,</li>
            <li>երթուղու փոփոխություն քաղաքում, գինը բարձրանում է,</li>
            <li>տարհանումից հրաժարվելը՝ պատվերի 50%-ը,</li>
            <li>հաճախորդի մեղքով պարապուրդը 2500 դրամ 30 րոպեի համար:</li>
          </ol>
        </div>
      </Container>
    </section>
  );
}

export default About;
