import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa';
import Logo from "../assets/icons/truck1.png";

function Header() {
  const phoneNumber = '098-01-87-88'; // Номер телефона для набора
  const hrPhoneNumber = '37498018788'; // Номер телефона для набора

  const handleNavClick = (event) => {
    event.preventDefault(); // Предотвращаем навигацию
    const targetId = event.target.getAttribute('href'); // Получаем значение атрибута href
    const targetElement = document.querySelector(targetId); // Находим элемент с указанным ID
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Прокручиваем к элементу с указанным ID
    }
  };

  return (
    <Navbar expand="lg" className=" bg-body-tertiary justify-content-center">
      <Container>
        <Navbar.Brand>
          <div className=" align-items-center justify-content-center" style={{ display: "flex" }}>
            Evakuator-24-7.am
            <div style={{ maxWidth: "50px", marginLeft: "10px" }}>
              <img style={{ maxWidth: "100%" }} src={Logo} alt="site.am" />
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="me-auto">
            <Nav.Link href="#about" onClick={handleNavClick}>Մեր մասին</Nav.Link>
            <Nav.Link href="#tariff" onClick={handleNavClick}>Ծառայություններ</Nav.Link>
            <Nav.Link href="#contact" onClick={handleNavClick}>Հետադարձ կապ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav.Link href={`tel:${hrPhoneNumber}`} className="d-flex align-items-center" style={{ backgroundColor: '#007bff', color: '#ffffff', padding: '5px 10px', borderRadius: '5px' }}>
          <FaPhone style={{ marginRight: '5px' }} />
          {phoneNumber}
        </Nav.Link>
      </Container>
    </Navbar>
  );
}

export default Header;
