import React, { useRef, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
function Contact() {
  const [validated, setValidated] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [showThanks, setShowThanks] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formElement = form.current;

  if (!formElement.checkValidity()) {
    e.stopPropagation();
    setValidated(true); // Set validated state to true to display validation messages
    return;
  }

    setValidated(true);

    // Custom phone validation
    const phoneField = form.elements["phone"];
    if (!isValidPhoneNumber(phoneField.value)) {
      e.preventDefault();
    } else {
      setPhoneError('');
      sendEmail();
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phonePattern = /^\d{3}-\d{3}-\d{3}$/;
    return phonePattern.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault()

    if (!form.current.checkValidity()) {
      e.stopPropagation();
      setValidated(true); // Set validated state to true to display validation messages
      return;
    }

    emailjs
      .sendForm('service_72zeslm', 'template_c58kozc', form.current, {
        publicKey: 'ls94dltIKC_VhgsAi',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="contact-section" id="contact">
      <Container className="mt-5 d-flex justify-content-center align-items-center">
        <div style={{maxWidth:"500px", width:"100%"}}>
          <h2 className="text-center mb-4">Հետադարձ կապ</h2>
          {showThanks ? (
            <div id="thanks" className="text-center">
            Մենք ստացանք ձեր նամակը!
            </div>
          ) : (
            <Form  ref={form} noValidate validated={validated} onSubmit={sendEmail}>
              <Form.Group controlId="name">
                <Form.Label>Անուն</Form.Label>
                <Form.Control name="name" type="text" placeholder="Enter your name" required />
                <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>էլ-հասցե</Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter your email" required />
                <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Հեռ․</Form.Label>
                <Form.Control name="phone" type="tel" placeholder="Enter your phone number" required />
                <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                <Form.Text className="text-muted">{phoneError}</Form.Text>
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Նամակ</Form.Label>
                <Form.Control name="message" as="textarea" style={{resize:"none"}} rows={3} placeholder="Enter your message" required />
                <Form.Control.Feedback type="invalid">Please enter your message.</Form.Control.Feedback>
              </Form.Group>
              <div className="text-center mt-4">
                <Button variant="primary" type="submit">
                  Ուղարկել
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Contact;
